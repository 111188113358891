import { Wrapper, ButtonPrimary, ButtonSecondary } from "./style";

const StickyButtons = ({buttonPrimaryLabel, buttonSecondaryLabel} : any) => (
  <Wrapper>
    {buttonPrimaryLabel &&
      <ButtonPrimary type="button">{buttonPrimaryLabel}</ButtonPrimary>
    }
    {buttonSecondaryLabel &&
      <ButtonSecondary type="button">{buttonSecondaryLabel}</ButtonSecondary>
    }
  </Wrapper>
);

export default StickyButtons;