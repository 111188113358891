import { Box } from "@mui/material";
import {
  CanvasVideoWrapper,
  TopicMainHeading,
  TopicMainDescription
} from "./style";
import { SparkIcon } from "components/Icons/SparkIcon";
import _ReactPlayer, { ReactPlayerProps } from "react-player/lazy";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

export const CanvasVideo = ({ topic }: { topic: any }) => {
  return (
    <CanvasVideoWrapper className="CanvasVideoWrapper">
      <Box textAlign={"center"}>
        <TopicMainHeading variant="h2">
          <SparkIcon />
          {topic?.title}
        </TopicMainHeading>
        <TopicMainDescription>{topic?.description}</TopicMainDescription>
      </Box>
      <div className="video-responsive">
        <ReactPlayer
          controls
          key={topic?.slug + topic?.video}
          url={topic?.video || ""}
          onError={(e) => console.log(e)}
        />
      </div>
    </CanvasVideoWrapper>
  );
};
