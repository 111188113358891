import { Button } from "@mui/material";
import DiscordIcon from "../../assets/Discord.svg";
import { CanvasChatWrapper, BoxOpenChat, BoxHeading, BoxSubheading } from "./style";

export const CanvasChat = () => {

  return (
    <CanvasChatWrapper className="CanvasChatWrapper">
      <BoxHeading
        variant="h2"
      >
        Questions? Let's chat
      </BoxHeading>
      <BoxOpenChat>
        <Button><img src={DiscordIcon} alt="discord" /> Open discord</Button>
      </BoxOpenChat>
      <BoxSubheading
        variant="h3"
      >
        <span className="can-status-bubble"></span>
        4967
      </BoxSubheading>
    </CanvasChatWrapper>
  );
};
