import { styled } from "@mui/material";

export const CanvasSidebarOpener = styled("div")`
  margin-bottom: 24px;

  @media (min-width: 1200px) {
    display: none;
  }

  .csw-btn-open{
    background-color: #000;
    border: 1px solid #E3C050;
    padding: 13px 20px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;

    &:hover{
      background-color: #3f3f3f;
      color: #fff;
    }

    svg{
      margin-left: 10px;
    }
  }
`;

export const CanvasSideContentWrapper = styled("div")`
  
  .cscwBoxWrapper{
    display: flex;
    column-gap: 48px;
  }

  .cscwDivider{
    border: dashed #373737;
    border-width: 2px 0 0;
    margin: 36px 0 60px;
  }
`;