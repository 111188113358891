import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArrowMoveUp = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M9 14.2501V3.75006" stroke="currentColor" strokeWidth="1.503" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M3.75 9.00006L9 3.75006L14.25 9.00006" stroke="currentColor" strokeWidth="1.503" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};