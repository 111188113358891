import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const UploadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M15.75 11.25V14.25C15.75 14.6478 15.5919 15.0293 15.3106 15.3107C15.0293 15.592 14.6478 15.75 14.25 15.75H3.75C3.35217 15.75 2.97064 15.592 2.68934 15.3107C2.40803 15.0293 2.25 14.6478 2.25 14.25V11.25" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M12.75 5.99999L8.99999 2.25L5.25 5.99999" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M9 2.25V11.25" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};