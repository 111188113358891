import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArrowRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M3.74994 9H14.2499" stroke="currentColor" strokeWidth="1.503" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M9 3.74976L14.25 8.99976L9 14.2498" stroke="currentColor" strokeWidth="1.503" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};