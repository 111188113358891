import ContactForm from "components/ContactForm";
import { Wrapper, Section, Description, SectionHeading, Address, AddressHeading, Col } from "./style";
import TwoColumnsGrid from "components/TwoColumnsGrid";

const FormSection = ({pTop, pBottom}: any) => (
  <Section
    className="FormSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <TwoColumnsGrid>
        <Col>
          <SectionHeading
            variant="h2"
          >
            Get in Touch
          </SectionHeading>
          <Description
            variant="body1"
          >
            "Hosting our website through 6ix Websites has been a game changer for us: everything is automatic, up-to-date, on-brand, and effortlessly accurate."
          </Description>
          <Address>
            <AddressHeading
              variant="caption"
            >
              President and CEO
            </AddressHeading>
            President and CEO <br />SR&ED Plus Nexus
          </Address>
        </Col>
        <Col>
          <ContactForm />
        </Col>
      </TwoColumnsGrid>
    </Wrapper>
  </Section>
);

export default FormSection;