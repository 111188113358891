import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LockOpenIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.1667 6.66663V5.83329C14.1667 3.53579 12.2975 1.66663 10 1.66663C7.70254 1.66663 5.83337 3.53579 5.83337 5.83329V8.33329H5.00004C4.08087 8.33329 3.33337 9.08079 3.33337 9.99996V16.6666C3.33337 17.5858 4.08087 18.3333 5.00004 18.3333H15C15.9192 18.3333 16.6667 17.5858 16.6667 16.6666V9.99996C16.6667 9.08079 15.9192 8.33329 15 8.33329H7.50004V5.83329C7.50004 4.45496 8.62171 3.33329 10 3.33329C11.3784 3.33329 12.5 4.45496 12.5 5.83329V6.66663H14.1667ZM15 9.99996L15.0017 16.6666H5.00004V9.99996H15Z" fill="currentColor"/>
    </SvgIcon>
  );
};