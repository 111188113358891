import { styled, Box, Typography, Divider } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 24px;

  @media (min-width: 768px){
    padding: 32px 30px;
  }
`;

export const IconHolder = styled(Box)`
  color: #E3C050;
  display: flex;
  align-items:center;
  justify-content: flex-start;
  line-height: 0;
  height: 25px;
  margin-bottom: 24px;
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  line-height: 1.5;
  font-size: 16px;

  @media (min-width: 768px){
    font-size: 18px;
  }
`;

export const Description = styled(Typography)`
  font-size: 16px;
  line-height: 1.25;
  color: #C0C0C0;
`;

export const Hr = styled(Divider)`
  border: solid rgba(227, 229, 232, 0.16);
  border-width: 1px 0 0;
  margin-top: 16px;
  margin-bottom: 16px;
`;