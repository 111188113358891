import { ImageHolder, Wrapper } from "./style";
import Image1 from "../../assets/img-icon-13.svg"
import Image2 from "../../assets/img-icon-14.svg"
import Image3 from "../../assets/img-icon-15.svg"
import Image4 from "../../assets/img-icon-16.svg"

const LogosList = ({}: any) => (
  <Wrapper>
    <ImageHolder>
      <img src={Image1} alt='' />
    </ImageHolder>
    <ImageHolder>
      <img src={Image2} alt='' />
    </ImageHolder>
    <ImageHolder>
      <img src={Image3} alt='' />
    </ImageHolder>
    <ImageHolder>
      <img src={Image4} alt='' />
    </ImageHolder>
  </Wrapper>
);

export default LogosList;