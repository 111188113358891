import { styled, Box, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';

export const Wrapper = styled('div')`
  
`;

export const FormGroup = styled(Box)`
  margin-bottom: 14px;
`;

export const LabelHeading = styled(Typography)`
  display: block;
  color: #8c8e95;
  font-size: 14px;
  margin-bottom: 3px;
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 44px;
  font-size: 14px;
  line-height: 18px;

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`;

export const TextAreaField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 120px;
  font-size: 14px;
  line-height: 18px;

  textarea{
    padding: 0;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  > div{
    display: block;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`;

export const ButtonWrapper = styled(Box)`
  margin-top: 24px;
`;

export const ButtonPrimary = styled(Button)`
  min-width: 108px;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 16px 36px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;