import { styled, Box, Typography, Divider } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 24px;
`;

export const ImageHolder = styled(Box)`
  margin: 0 0 24px;
  overflow: hidden;
  border-radius: 9px;
  
  img{
    border-radius: inherit;
    width: 100%;
    height: auto;
  }
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 1.35;
`;