import { styled, Box, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 32px;
  color: #c0c0c0;

  @media (min-width: 768px){
    padding-bottom: 51px;
    display: flex;
    align-items: flex-start;
    gap: 45px;
  }
`;

export const ImageHolder = styled(Box)`
  position: relative;
  z-index: 1;
  width: 215px;
  flex-shrink: 0;
  margin: 0 0 40px;

  @media (min-width: 768px){
    margin: 0;
  }
  @media (min-width: 992px){
    width: 215px;
  }

  &:after{
    content: '';
    z-index: -1;
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 97px;
    height: 97px;
    border: solid #E3C050;
    border-width: 0 10px 10px 0;
  }

  img{
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`;

export const DescriptionWrapper = styled(Box)`
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  padding-left: 45px;

  @media (min-width: 768px){
    font-size: 18px;
    padding-left: 57px;
  }
`;

export const QuoteIconWrapper = styled(Box)`
  position: absolute;
  left: -8px;
  top: 5px;
  max-width: 35px;

  @media (min-width: 768px){
    top: 4px;
    left: 0;
  }

  img{
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`;

export const CiteWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px){
    padding-right: 100px;
    display: block;
  }
`;

export const TextWrapper = styled(Box)`
  margin-bottom: 24px;
`;

export const AuthorHeading = styled(Typography)`
  font: 900 20px/1.167 'Playfair Display', 'Times New Roman', Times, serif;
  margin-bottom: 8px;
  color: #e9e9e9;

  @media (min-width: 768px){
    font-size: 26px;
  }
  @media (min-width: 992px){
    font-size: 30px;
  }
`;

export const Cite = styled(Typography)`
  color: #D3B054;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;

  @media (min-width: 768px){
    font-size: 16px;
  }
  @media (min-width: 992px){
    font-size: 18px;
  }
`;

export const LogoWrapper = styled(Box)`
  max-width: 300px;
  margin-top: 20px;

  @media (min-width: 768px){
    margin-top: 0;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  img{
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`;