import SectionHeader from "components/SectionHeader";
import { Wrapper, Section, DescriptionText } from "./style";
import ThreeColumnsGrid from "components/ThreeColumnsGrid";
import CardBox from "components/CardBox";
import ProductColumn from "components/ProductColumn";
import Image1 from "../../assets/img-icon-07.svg"
import Image2 from "../../assets/img-icon-08.svg"
import Image3 from "../../assets/img-icon-09.svg"
import Image4 from "../../assets/img-icon-10.svg"
import Image5 from "../../assets/img-icon-11.png"
import Image6 from "../../assets/img-icon-12.svg"

const ProductColumnsSection = ({pTop, pBottom, children}: any) => (
  <Section
    className="ProductColumnsSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader
        headingLarge
        heading="Automatically integrates with&hellip;"
      >
        <DescriptionText
          variant="body1"
        >
          6ix products are natively designed to be interoperable with <a href="#">6ixOS</a>
        </DescriptionText>
      </SectionHeader>
      <ThreeColumnsGrid>
        <CardBox>
          <ProductColumn
            imageUrl={Image1}
            width="50"
            height="36"
            heading="Artificial Intelligence"
            excerpt="Generate event ideas and analyze your data."
          />
        </CardBox>
        <CardBox>
          <ProductColumn
            imageUrl={Image2}
            width="78"
            height="36"
            heading="Promo"
            excerpt="Promote your event to increase registrations and replays."
          />
        </CardBox>
        <CardBox>
          <ProductColumn
            imageUrl={Image3}
            width="84"
            height="36"
            heading="Courses"
            excerpt="Convert your event content into a free or paid course."
          />
        </CardBox>
        <CardBox>
          <ProductColumn
            imageUrl={Image4}
            width="112"
            height="24"
            heading="VRIFY"
            excerpt="Present your VRIFY deck and allow investors to interact with it."
          />
        </CardBox>
        <CardBox>
          <ProductColumn
            imageUrl={Image5}
            width="137"
            height="26"
            heading="CEO.CA"
            excerpt="Embed your CEO.CA stream into your live event."
          />
        </CardBox>
        <CardBox>
          <ProductColumn
            imageUrl={Image6}
            width="36"
            height="36"
            heading="Mailchimp"
            excerpt="Synchronize your event data with Mailchimp."
          />
        </CardBox>
      </ThreeColumnsGrid>
    </Wrapper>
  </Section>
);

export default ProductColumnsSection;