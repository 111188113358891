import { Link } from "@mui/material";
import { SecondaryLink, PrimaryLink, Wrapper } from "./style";

const ButtonsFooter = ({ primaryLabel, primaryLink, secondaryLabel, secondaryLink }: any) => (
  <Wrapper>
    {primaryLabel &&
      <PrimaryLink href={primaryLink}>{primaryLabel}</PrimaryLink>
    }
    {secondaryLabel &&
      <SecondaryLink href={secondaryLink}>{secondaryLabel}</SecondaryLink>
    }
  </Wrapper>
);

export default ButtonsFooter;