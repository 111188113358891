import { Typography, styled } from "@mui/material";
import { Box, Button, Divider } from "@mui/material";
import { CanvasSidebar } from "components/CanvasSidebar";
import { CanvasVideo } from "components/CanvasVideo";
import { CanvasChat } from "components/CanvasChat";
import { useEffect, useState } from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useNavigate, useParams } from "react-router-dom";
import { useCheckoutMutation, useGetCourseQuery } from "api/courses";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signInWithCredentialResponse } from "../../firebase";
import { superAdmin } from "common/constants";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import {
  AuthorHeading,
  AuthorWrapper,
  AvatarBox,
  CourseHomeWrapper,
  CustomDivider,
  DescriptionWrapper,
  MainHeading,
  RowHeading,
  RowWrapper,
  TagWrapper,
  TagHeading,
  TextPara,
  CurseButtonWrapper,
  BuyCourseButton,
  CourseBuyedHeading,
  ListWrapper,
  ListColumn,
  ListColumnBox,
  IconWrapper,
  ColumnHeading,
  ColumnDescription,
  ButtonPrimary,
  NavigatorWrapper,
} from "./style";
import { LockIcon } from "components/Icons/LockIcon";
import { LockOpenIcon } from "components/Icons/LockOpenIcon";
import { ArrowRightIcon } from "components/Icons/ArrowRightIcon";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { LoginPopup } from "components/Account/Login/LoginPopup";
import { toast } from "react-toastify";
import { error } from "console";

export const CanvasSidebarOpener = styled("div")`
  margin-bottom: 24px;

  @media (min-width: 1200px) {
    display: none;
  }
`;

export const CourseHome = () => {
  const [loginRequest, setLoginRequest] = useState(false);
  const [userInfo, setUserInfo] = useState<any>("");
  const { courseId } = useParams();
  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();
  const [checkout] = useCheckoutMutation();
  const { data } = useGetCourseQuery(courseId, {
    refetchOnMountOrArgChange: true,
  });

  const handleSidebarCollapse = function () {
    document.body.classList.toggle("canvasSidebarActive");
  };

  const loginWithWithCredential = async (response: any) => {
    const data = await signInWithCredentialResponse(response);
    if (data) {
      setLoginRequest(false);
      navigate(0);
    }
  };

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  const initializeGSI = () => {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      cancel_on_tap_outside: true,
      callback: loginWithWithCredential,
    });

    // @ts-ignore
    google.accounts.id.prompt();
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
      const DATA = {
        // @ts-ignore
        tokenID: user.accessToken,
        name: user.displayName,
        email: user.email,
        profileImage: user.photoURL,
      };
      setUserInfo(DATA);
      localStorage.setItem("uid", user.uid);
    } else {
      initializeGSI();
    }
  }, [user, loading]);

  const purchaseHandler = () => {
    checkout({ slug: courseId, email: user?.email }).then((res: any) => {
      if (res?.data?.url) {
        window.location = res?.data?.url;
      } else {
        toast.error(res?.error?.data?.error);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CourseHomeWrapper className="CourseHomeWrapper">
        {user &&
          (user?.uid === data?.uid || superAdmin.includes(user?.uid)) && (
            <CurseButtonWrapper className="mb">
              <ButtonPrimary
                onClick={() => navigate(`/course/${courseId}/edit`)}
              >
                <EditIcon /> Edit Course
              </ButtonPrimary>
            </CurseButtonWrapper>
          )}
        <MainHeading variant="h1">{data?.title}</MainHeading>
        <CanvasVideo topic={data?.tasks[0]?.topics[0]} />
        <AuthorWrapper>
          <AvatarBox>
            <img src={data?.creator?.photoURL} />
          </AvatarBox>
          <Box>
            <AuthorHeading variant="h3">
              {`Taught by ${data?.creator?.displayName}`}
            </AuthorHeading>
          </Box>
        </AuthorWrapper>
        <CustomDivider />
        <DescriptionWrapper>
          <ReactMarkdown
            children={data?.document}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw] as any}
          />
        </DescriptionWrapper>
        <CustomDivider />
        <RowWrapper>
          <RowHeading variant="h2">
            When was the course last updated?
          </RowHeading>
          <TagWrapper>
            <TagHeading variant="caption">
              {`Updated at ${data?.updatedAt}`}
            </TagHeading>
          </TagWrapper>
        </RowWrapper>
        <CustomDivider />
        {user && user?.uid ? (
          <>
            {data?.purchased ? (
              <>
                <RowWrapper>
                  <RowHeading variant="h2">How do I enroll?</RowHeading>
                  <TextPara variant="body1">
                    The first few videos are free, so just give it try. When you
                    reach a paid module, you will be asked to enroll.
                  </TextPara>
                </RowWrapper>
                <CurseButtonWrapper>
                  <CourseBuyedHeading variant="h3">
                    <CelebrationIcon /> You have Full access to this Course
                  </CourseBuyedHeading>
                </CurseButtonWrapper>
              </>
            ) : (
              <>
                <RowWrapper>
                  <RowHeading variant="h2">How do I enroll?</RowHeading>
                  <TextPara variant="body1">
                    The first few videos are free, so just give it try. When you
                    reach a paid module, you will be asked to enroll in the
                    course.
                  </TextPara>
                </RowWrapper>
                <CurseButtonWrapper>
                  <BuyCourseButton onClick={() => purchaseHandler()}>
                    Buy this course &nbsp;
                    <strong>{data?.price ? "$" + data?.price : "Free"}</strong>
                  </BuyCourseButton>
                </CurseButtonWrapper>
              </>
            )}
          </>
        ) : (
          <>
            <RowWrapper>
              <RowHeading variant="h2">How do I enroll?</RowHeading>
              <TextPara variant="body1">
                The first few videos are free, so just give it try. When you
                reach a paid module, you will be asked to enroll in the course.
              </TextPara>
            </RowWrapper>
            <CurseButtonWrapper>
              <BuyCourseButton onClick={() => handleLogin()}>
                click to login/register
              </BuyCourseButton>
            </CurseButtonWrapper>
          </>
        )}
        <CustomDivider />
        <Box>
          <RowHeading variant="h2" className="alt">
            Chapters
          </RowHeading>
          <ListWrapper>
            {data?.tasks?.map((task: any, index: number) => (
              <>
                {task.topics.map((topic: any, index: any) => (
                  <ListColumn
                    onClick={() =>
                      navigate(`/course/${courseId}/${topic?.slug}`)
                    }
                    className={`${topic.locked ? "locked" : ""}`}
                  >
                    <ListColumnBox>
                      {topic.locked ? (
                        <IconWrapper>
                          <LockIcon />
                        </IconWrapper>
                      ) : (
                        <IconWrapper>
                          <LockOpenIcon />
                        </IconWrapper>
                      )}
                      <ColumnHeading variant="h3">
                        {`${topic?.title}`}
                        {/* {JSON.stringify(topic)} */}
                      </ColumnHeading>
                      <ColumnDescription variant="body1">
                        {`${data?.description}`}
                      </ColumnDescription>
                    </ListColumnBox>
                  </ListColumn>
                ))}
              </>
            ))}
          </ListWrapper>
          <NavigatorWrapper>
            <ButtonPrimary>
              Next
              <ArrowRightIcon />
            </ButtonPrimary>
          </NavigatorWrapper>
        </Box>
      </CourseHomeWrapper>
      {!userInfo && loginRequest ? (
        <LoginPopup handleLogin={handleLogin} />
      ) : null}
    </>
  );
};
