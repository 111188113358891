import { styled, Box, Typography, Button } from "@mui/material";

export const CourseHomeWrapper = styled("div")`
  width: 100%;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export const MainHeading = styled(Typography)`
  font-family: 'Playfair Display', 'Times New Roman', Times, serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 16px;

  @media (min-width: 768px){
    font-size: 46px;
    line-height: 50px;
  }
  @media (min-width: 992px){
    font-size: 56px;
    line-height: 60px;
  }
`;

export const Subheading = styled(Typography)`
  color: #C0C0C0;
  font-size: 22px;
  line-height: 1.1;
  font-weight: 700;
`;

export const AuthorWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
`;

export const AvatarBox = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  overflow: hidden;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
`;

export const AuthorHeading = styled(Typography)`
  color: #C0C0C0;
  font-size: 18px;
  line-height: 1.1;
  font-weight: 400;
`;

export const CustomDivider = styled(Box)`
  width: 100%;
  height: 1px;
  background: linear-gradient(to right,  rgba(19,19,19,0) 0%,rgba(55,55,55,1) 50%,rgba(55,55,55,1) 50%,rgba(19,19,19,0) 100%);
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const DescriptionWrapper = styled(Box)`
  font-size: 16px;
  line-height: 24px;
  color: #c0c0c0;

  h2{
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 18px;
    color: #fff;

    @media (min-width: 768px){
      font-size: 30px;
      line-height: 42px;
    }
    @media (min-width: 992px){
      font-size: 36px;
      line-height: 48px;
    }
  }

  ul{
    list-style: none;
    margin: 0;
    padding: 0 0 0 1rem;
    color: #8C8E95;
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto, Arial, sans-serif;

    > li{
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  blockquote{
    margin: 0;
    padding: 24px 26px;
    position: relative;
    background-color: #1F1F1F;
    color: #C0C0C0;
    font-size: 16px;
    line-height: 24px;
    border: solid #E3C050;
    font-family: Roboto, Arial, sans-serif;
    border-width: 0 0 0 4px;
    overflow: hidden;

    p{

      &:only-child{
        marging: 0;
      }
    }
  }
`;

export const RowWrapper = styled(Box)`
  width: 100%;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
`;

export const TagWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
`;

export const RowHeading = styled(Typography)`
  font-family: 'Playfair Display', 'Times New Roman', Times, serif;
  font-weight: 900;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 24px;

  @media (min-width: 992px){
    font-size: 44px;
    line-height: 56px;

    &.alt {
      margin-bottom: 40px;
    }
  }
`;

export const TagHeading = styled(Typography)`
  color: #fff;
  font-size: 16px;
  line-height: 1.1;
  padding: 6px 12px;
  font-weight: 400;
  background-color: #11B585;
  border-radius: 4px;
`;

export const TextPara = styled(Typography)`
  fontSize: 18px;
  lineHeight: 1.5;
  color: #c0c0c0;
`;

export const CurseButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 36px;

  &.mb{
    margin-top: 0;
    margin-bottom: 36px;

    button{

      .MuiSvgIcon-root{
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
`;

export const BuyCourseButton = styled(Button)`
  padding: 24px 36px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 11px;
  border: 1px solid #454648;
  background-color: #2b2b2b;
  color: #e9e9e9;

  &:hover{
    border-color: #E3C050;
    background-color: #191509;
    color: #E3C050;
  }

  strong{
    display: inline-block;
    border: 1px solid #575757;
    background-color: #454648;
    color: #e9e9e9;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 700;
    padding: 5px 8px;
    border-radius: 6px;
    margin-left: 6px;
  }
`;

export const CourseBuyedHeading = styled(Typography)`
  padding: 24px 36px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  border: 1px solid #E3C050;
  background-color: #191509;
  color: #E3C050;
  border-radius: 11px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  .MuiSvgIcon-root{
    width: 24px;
    height: 24px;
  }
`;

export const ListWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -12px;
  margin-right: -12px;
  row-gap: 32px;
`;

export const ListColumn = styled(Box)`
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;

  @media (min-width: 576px){
    width: 50%;
  }
  @media (min-width: 992px){
    width: 33.333%;
  }
`;

export const ListColumnBox = styled(Box)`
  width: 100%;
  position: relative;
  cursor: pointer;
  margin-bottom: 12px;
  border: 1px solid #E3C050;
  background-color: #131313;
  border-radius: 11px;
  overflow: hidden;
  text-align: left;
  padding: 34px 32px;
  transition: background-color 0.3s ease;

  &:hover{
    background-color: #000;
  }

  .locked &{
    background-color: #1f1f1f;
    border-color: #2f2f2f;
    pointer-events: none;
  }
`;

export const IconWrapper = styled(Box)`
  margin-bottom: 17px;
  color: #11B585;
  line-height: 0;

  .locked &{
    color: #8C8E95;
  }

  .MuiSvgIcon-root{
    width: 20px;
    height: 20px;
    vertical-align: top;
  }
`;

export const ColumnHeading = styled(Typography)`
  color: #E9E9E9;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 16px;
  font-weight: 700;

  .locked &{
    color: #8C8E95;
  }
`;

export const ColumnDescription = styled(Typography)`
  color: #C0C0C0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;

  .locked &{
    color: #8C8E95;
  }
`;

export const NavigatorWrapper = styled(Box)`
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  justify-content: center;
  display: none;
`;

export const ButtonPrimary = styled(Button)`
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  border-radius: 6px;
  min-width: 223px;
  padding: 17px 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1;
  border: 1px solid #E3C050;

  &:hover{
    background-color: #191509;
    color: #fff;
    border-color: #E3C050;
  }

  .MuiSvgIcon-root{
    width: 15px;
    height: 18px;
    margin-left: 8px;
  }
`;