import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Rings } from "react-loader-spinner";
import { signInWithUserEmailLinkVerify } from "../firebase";

let reqCheck = true;
const CallbackPage = () => {
  const navigate = useNavigate();

  const loginVerify = async () => {
    if (reqCheck) {
      reqCheck = false;
      let email;
      let url;
      try {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const continueUrl = params.get("continueUrl");
        if (continueUrl) {
          url = new URL(continueUrl);
          email = url?.searchParams?.get("ref_l_email");
        }
      } catch (err) {
        email = "";
      }

      await signInWithUserEmailLinkVerify(email);
      if (url) {
        navigate(url?.pathname || "/");
      } else {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    loginVerify();
  });
  return (
    <div>
      <Rings
        height="80"
        width="80"
        color="#fff"
        radius="6"
        wrapperStyle={{}}
        wrapperClass="sixPromoLoader"
        visible={true}
        ariaLabel="rings-loading"
      />
    </div>
  );
};
export default CallbackPage;
