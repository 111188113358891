import { Button } from "@mui/material";
import { ArrowLeftIcon } from "../../components/Icons/ArrowLeftIcon";
import { SparkIcon } from "../../components/Icons/SparkIcon";
import CloseIcon from "@mui/icons-material/Close";
import { BoxWrapper, CanvasSidebarWrapper, BoxWrap, BoxTopicWrapper, TopHeading, BackLink, SidebarSectionWrapper } from "./style";
import { CourseLink } from "components/Link";
import { useNavigate } from "react-router-dom";
import { useKeyDown } from "hook/useKeyDown";

export const CanvasSidebar = ({
  course,
}: {
  course: any;
}) => {
  const navigate = useNavigate();
  const handleSidebarCollapse = function () {
    document.body.classList.toggle("canvasSidebarActive");
  };

  // Example usage:
  useKeyDown(() => {
    navigate("/");
  }, ["b"]);

  return (
    <CanvasSidebarWrapper className="CanvasSidebarWrapper">
      <Button className="csw-btn-close" onClick={handleSidebarCollapse}>
        <CloseIcon />
      </Button>
      <BoxWrapper>
        <BoxWrap>
          <BackLink
            className="cswLink"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon />
            ctrl+b
          </BackLink>
        </BoxWrap>

        {course.map((data: any) => (
          <SidebarSectionWrapper>
            <BoxTopicWrapper>
              <TopHeading>
                <SparkIcon />
                {data.name}
              </TopHeading>
            </BoxTopicWrapper>
            {data.topics.map((topic: any) => (
              <CourseLink topic={topic} />
            ))}
          </SidebarSectionWrapper>
        ))}
      </BoxWrapper>
    </CanvasSidebarWrapper>
  );
};
