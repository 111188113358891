import { useEffect } from 'react';
export const useKeyDown = (callback: Function, keys: any) => {
  let keysPressed:any = {};
  const onKeyDown = (event: any) => {
    keysPressed[event.key] = true;
    const wasAnyKeyPressed = keys.some((key: any) => keysPressed['Control'] && event.key === key);
    if (wasAnyKeyPressed) {
      event.preventDefault();
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', (event: any) => {
      delete keysPressed[event.key];
    });

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

};

