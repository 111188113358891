import { Link } from "@mui/material";
import { LockIcon } from "components/Icons/LockIcon";
import { useNavigate, useParams } from "react-router";

export const CourseLink = ({
  topic,
}: {
  topic: any;
}) => {
  const { videoId } = useParams();
  const { courseId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      {topic.locked ? (
        <Link
          className="cswListLink locked"
        >
          <LockIcon />
          <span className="can-text">{topic.title}</span>
          <time className="can-tag">2:01</time>
        </Link>
      ) : (
        <Link
          className={topic.slug === videoId &&
              "cswcswLink active" ||
            "cswcswLink"
          }
          onClick={() => navigate(`/course/${courseId}/${topic.slug}`)}
        >
          {topic.title}
        </Link>
      )}
    </>
  );
};
