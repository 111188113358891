import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;

  .CardBox{

    @media (max-width: 575px){
      max-width: 316px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
`;

export const DescriptionText = styled(Typography)`
  font-size: 20px;
  line-height: 1.5;
  margin-top: 12px;
  color: #C0C0C0;

  a{
    color: #E3C050;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }
`;