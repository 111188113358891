import { styled, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

export const MainHeading = styled(Typography)`
  font: 900 40px/1.25 'Playfair Display', 'Times New Roman', Times, serif;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 992px){
    font-size: 50px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 1200px){
    font-size: 60px;
  }
`;

export const DescriptionPara = styled(Typography)`
  font-size: 16px;
  line-height: 1.5;
  color: #C0C0C0;
  font-weight: 700;

  @media (min-width: 768px){
    font-size: 20px;
    line-height: 1.5;
  }
  @media (min-width: 992px){
    font-size: 22px;
  }
`;