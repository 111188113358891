// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from "react";
import Plyr from "plyr";
import PlayIcon from "../../assets/video/volume.svg";
import ZapIcon from "../../assets/video/zap.svg";
import "plyr/dist/plyr.css";
import { Wrapper } from "./style";
const markers = [
  { time: 1, label: "6ix Events" },
  { time: 5, label: "Digital Roadshow Platform" },
  { time: 8, label: "Comprehensive Investor Profiles" },
  { time: 13, label: "1-Click Investor Meetings" },
  { time: 15, label: "Data-Driven Investor Reports" },
  {
    time: 18,
    label: "Reach investors, attract capital & improve your reputation",
  },
];

const LandingVideo = () => {
  const videoRef = useRef(null);
  const speedControlAreaRef = useRef(null);
  const [completionTime, setCompletionTime] = useState("");
  const [isMuted, setIsMuted] = useState(true);
  const [isActiveSetting, setIsActiveSetting] = useState(false);
  const [currentSpeed, setCurrentSpeed] = useState(1);
  const [totalVideoTime, setTotalVideoTime] = useState("");

  const [isMarkersListVisible, setIsMarkersListVisible] = useState(false);

  const toggleSettingBox = useCallback(() => {
    setIsActiveSetting(current => !current);
  }, []);

  const toggleMute = useCallback(() => {
    const player = videoRef.current.plyr;

    if (player) {
      player.muted = !player.muted;
      setIsMuted(player.muted);
    }
  }, []);

  const setSpeed = useCallback(speed => {
    if (videoRef.current) {
      videoRef.current.playbackRate = speed;
    }
  }, []);

  const handleSpaceKeyPress = useCallback(event => {
    if (event.code === "Space") {
      event.preventDefault();
      if (videoRef.current) {
        videoRef.current.paused
          ? videoRef.current.play()
          : videoRef.current.pause();

        // Update completion time when the video is paused
        if (videoRef.current.paused) {
          updateCompletionTime();
        }
      }
    }
  }, []);

  const handleMarkerClick = useCallback(time => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  }, []);

  const toggleMarkersList = useCallback(() => {
    setIsMarkersListVisible(current => !current);
  }, []);

  useEffect(() => {
    const player = new Plyr(videoRef.current, {
      title: "Example Title",
      controls: [
        "play-large",
        "play",
        "current-time",
        "progress",
        "mute",
        "volume",
        "fullscreen",
      ],
      muted: true,
      markers: {
        enabled: true,
        points: markers,
      },
      keyboard: { global: true },
    });

    // Set initial mute state
    player.muted = true;

    // Event listener for the settings button click

    // Add a custom button to Plyr controls
    const customButton = document.createElement("button");
    const settingImg = `<svg x="0px" y="0px" viewBox="0 0 40 34" enable-background="new 0 0 40 34" aria-hidden="true" style="fill: rgb(255, 255, 255); height: 100%; left: 0px; stroke-width: 0px; top: 0px; width: 100%;"><g><g><path d="M28.3,16.4h-1.9c-0.4,0-0.8-0.3-0.9-0.7l-0.4-1.1c-0.2-0.3-0.1-0.8,0.2-1.1l1.3-1.3c0.3-0.3,0.3-0.7,0-1l-0.4-0.4c-0.3-0.3-0.7-0.3-1,0l-1.3,1.3c-0.3,0.3-0.8,0.3-1.1,0.1l-1.1-0.5c-0.4-0.1-0.7-0.5-0.7-0.9V9.1c0-0.4-0.3-0.7-0.7-0.7h-0.6c-0.4,0-0.7,0.3-0.7,0.7v1.7c0,0.4-0.3,0.8-0.7,0.9l-1.2,0.5c-0.3,0.2-0.8,0.1-1.1-0.2l-1.2-1.2c-0.3-0.3-0.7-0.3-1,0l-0.4,0.4c-0.3,0.3-0.3,0.7,0,1l1.2,1.2c0.3,0.3,0.3,0.8,0.1,1.1l-0.5,1.2c-0.1,0.4-0.5,0.7-0.9,0.7h-1.6c-0.4,0-0.7,0.3-0.7,0.7v0.6c0,0.4,0.3,0.7,0.7,0.7h1.6c0.4,0,0.8,0.3,0.9,0.7l0.5,1.2c0.2,0.3,0.1,0.8-0.1,1.1l-1.2,1.2c-0.3,0.3-0.3,0.7,0,1l0.4,0.4c0.3,0.3,0.7,0.3,1,0l1.2-1.2c0.3-0.3,0.8-0.3,1.1-0.2l1.2,0.5c0.4,0.1,0.7,0.5,0.7,0.9v1.7c0,0.4,0.3,0.7,0.7,0.7h0.6c0.4,0,0.7-0.3,0.7-0.7V24c0-0.4,0.3-0.8,0.7-0.9l1.1-0.5c0.3-0.2,0.8-0.1,1.1,0.1l1.3,1.3c0.3,0.3,0.7,0.3,1,0l0.4-0.4c0.3-0.3,0.3-0.7,0-1l-1.3-1.3C25,21,25,20.5,25.1,20.2l0.4-1.1c0.1-0.4,0.5-0.7,0.9-0.7h1.9c0.4,0,0.7-0.3,0.7-0.7v-0.6C29,16.7,28.7,16.4,28.3,16.4z M23.8,17.5c0,2.2-1.8,3.9-3.9,3.9c-2.2,0-3.9-1.8-3.9-3.9s1.7-3.9,3.9-3.9C22.1,13.6,23.8,15.3,23.8,17.5z"></path></g></g></svg>`;
    customButton.className = "plyr__control plyr__controls__item";
    customButton.id = "customSettingBtn";
    customButton.innerHTML = settingImg;

    const customButtonList = document.createElement("button");
    const listImg = `<svg x="0px" y="0px" viewBox="0 0 40 34" enable-background="new 0 0 40 34" aria-hidden="true" style="fill: rgb(255, 255, 255); height: 100%; left: 0px; stroke-width: 0px; top: 0px; width: 100%;"><g><g><circle cx="10.6" cy="10.1" r="1.6"></circle><path d="M29.5,10.1c0,0.6-0.5,1.1-1.1,1.1H15.7c-0.6,0-1.1-0.5-1.1-1.1l0,0c0-0.6,0.5-1.1,1.1-1.1h12.7C29,9.1,29.5,9.5,29.5,10.1L29.5,10.1z"></path></g><g><circle cx="10.6" cy="16.8" r="1.6"></circle><path d="M29.5,16.8c0,0.6-0.5,1.1-1.1,1.1H15.7c-0.6,0-1.1-0.5-1.1-1.1l0,0c0-0.6,0.5-1.1,1.1-1.1h12.7C29,15.7,29.5,16.2,29.5,16.8L29.5,16.8z"></path></g><g><circle cx="10.6" cy="23.4" r="1.6"></circle><path d="M29.5,23.4c0,0.6-0.5,1.1-1.1,1.1H15.7c-0.6,0-1.1-0.5-1.1-1.1l0,0c0-0.6,0.5-1.1,1.1-1.1h12.7C29,22.4,29.5,22.9,29.5,23.4L29.5,23.4z"></path></g></g></svg>`;
    customButtonList.className = "plyr__control plyr__controls__item";
    customButtonList.id = "customListBtn";
    customButtonList.innerHTML = listImg;

    player.on("loadeddata", () => {
      const controls = player.elements.controls;
      if (controls) {
        controls.insertBefore(customButton, controls.children[4]);
        controls.insertBefore(customButtonList, controls.children[5]);
      }

      // Set total video time
      const duration = player.duration;
      const totalHours = Math.floor(duration / 3600);
      const totalMinutes = Math.floor((duration % 3600) / 60);
      const totalSeconds = Math.floor(duration % 60);

      setTotalVideoTime(
        `${totalHours} hr ${String(totalMinutes).padStart(2, "0")} min ${String(
          totalSeconds
        ).padStart(2, "0")} sec`
      );

      // Set video speed to 1x on page refresh
      setSpeed(1);
      setCurrentSpeed(1);
    });

    customButton.addEventListener("click", event => {
      // Prevent the click event from propagating to the document click event
      event.stopPropagation();
      toggleSettingBox();
    });

    customButtonList.addEventListener("click", event => {
      // Prevent the click event from propagating to the document click event
      event.stopPropagation();
      toggleMarkersList();
    });

    // Event listener to close speed-control-area when clicking outside of it
    const handleClickOutside = event => {
      if (
        speedControlAreaRef.current &&
        !speedControlAreaRef.current.contains(event.target)
      ) {
        setIsActiveSetting(false);
        setIsMarkersListVisible(false);
      }
    };

    // Attach the event listeners to the document
    document.addEventListener("click", handleClickOutside);

    const currentVideo = videoRef.current;
    if (currentVideo) {
      currentVideo.addEventListener("timeupdate", updateCompletionTime);
    }

    return () => {
      if (currentVideo) {
        currentVideo.removeEventListener("timeupdate", updateCompletionTime);
        player.off("settings");
      }

      // Remove the event listeners when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, [
    toggleSettingBox,
    toggleMute,
    setSpeed,
    handleSpaceKeyPress,
    toggleMarkersList,
  ]);

  const updateCompletionTime = () => {
    if (videoRef.current) {
      const duration = videoRef.current.duration;
      const currentTime = videoRef.current.currentTime;
      const remainingTime =
        (duration - currentTime) / videoRef.current.playbackRate;

      const hours = Math.floor(remainingTime / 3600);
      const minutes = Math.floor((remainingTime % 3600) / 60);
      const seconds = Math.floor(remainingTime % 60);

      setCompletionTime(
        `${hours} hr ${String(minutes).padStart(2, "0")} min ${String(
          seconds
        ).padStart(2, "0")} sec`
      );
    }
  };

  return (
    <>
      <Wrapper className="video-wrap-body">
        <div className="video-wrap">
          <button
            className={
              isMuted ? "click-for-sound eee" : "click-for-sound display-none"
            }
            id="clickForSound"
            onClick={toggleMute}
          >
            <span className="click-for-sound-text">Click for sound</span>{" "}
            <img src={PlayIcon} alt="Play Icon" />
          </button>
          <div
            ref={speedControlAreaRef}
            className={`speed-control-area ${isActiveSetting ? "visible" : ""}`}
            id="speedControlArea"
          >
            <div className="speed-control-area-num speed-control-area-row">
              <button
                className={`x-btn ${
                  currentSpeed === 0.8 ? "light-text active" : ""
                }`}
                onClick={() => {
                  setSpeed(0.8);
                  setCurrentSpeed(0.8);
                }}
              >
                0.8x
              </button>
              <button
                className={`x-btn ${
                  currentSpeed === 1 ? "light-text active" : ""
                }`}
                onClick={() => {
                  setSpeed(1);
                  setCurrentSpeed(1);
                }}
              >
                1x
              </button>
              <button
                className={`x-btn ${
                  currentSpeed === 1.2 ? "light-text active" : ""
                }`}
                onClick={() => {
                  setSpeed(1.2);
                  setCurrentSpeed(1.2);
                }}
              >
                1.2x
              </button>
              <button
                className={`x-btn ${
                  currentSpeed === 1.5 ? "light-text active" : ""
                }`}
                onClick={() => {
                  setSpeed(1.5);
                  setCurrentSpeed(1.5);
                }}
              >
                1.5x
              </button>
              <button
                className={`x-btn ${
                  currentSpeed === 1.7 ? "light-text active" : ""
                }`}
                onClick={() => {
                  setSpeed(1.7);
                  setCurrentSpeed(1.7);
                }}
              >
                1.7x
              </button>
              <button
                className={`x-btn ${
                  currentSpeed === 2 ? "light-text active" : ""
                }`}
                onClick={() => {
                  setSpeed(2);
                  setCurrentSpeed(2);
                }}
              >
                2x
              </button>
              <button
                className={`x-btn ${
                  currentSpeed === 2.5 ? "light-text active" : ""
                }`}
                onClick={() => {
                  setSpeed(2.5);
                  setCurrentSpeed(2.5);
                }}
              >
                2.5x
              </button>
            </div>
            <div className="speed-control-area-time speed-control-area-row">
              <span>
                <del>{totalVideoTime}</del>
              </span>
              <img src={ZapIcon} alt="" />
              <span className="vid-light-text">{completionTime}</span>
            </div>
          </div>

          <div
            className={`markers-list ${isMarkersListVisible ? "visible" : ""}`}
          >
            <ul>
              {markers.map((marker, index) => (
                <li key={index} onClick={() => handleMarkerClick(marker.time)}>
                  <span className="markers-list-time">{marker.time} sec </span>
                  <span className="markers-list-text"> {marker.label}</span>
                </li>
              ))}
            </ul>
          </div>

          <video
            ref={videoRef}
            src="https://6ix-events-pro.s3.amazonaws.com/6ixvideo/file/6ix-Events-Promo.mp4"
            id="player"
            muted
            data-plyr-config='{ "title": "Example Title", "autoplay": true, "muted": true, "invertTime": false }'
          ></video>
        </div>
      </Wrapper>
    </>
  );
};

export default LandingVideo;
