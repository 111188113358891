import { styled, Box, Typography, TextField, Divider, Button, FormControlLabel } from '@mui/material';
import { Field } from "formik";

export const CourseBuilderBox = styled('div')`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 960px;
  
  .ck-editor{
    
    .ck.ck-content{
      background-color: #131313;
      color: #E9E9E9;
      border: 1px solid #373737 !important;
      border-top: 0 !important;
    }

    .ck.ck-toolbar{
      border-bottom: 0 !important;
    }

    .ck.ck-button:active, .ck.ck-button:focus, a.ck.ck-button:active, a.ck.ck-button:focus{
      box-shadow: none;
    }
  }
`;

export const ButtonMoveUp = styled(Button)`
  border: 0;
  background-color: transparent;
  min-width: 1px;
  padding: 0;
  align-self: flex-start;
  width: 20px;
  height: 24px;
  color: #575757;

  &:hover{
    color: #e9e9e9;
    background-color: transparent;
  }

  .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
  }

  + .MuiTypography-h2{
    padding-left: 0;
  }
`;
export const ButtonMoveDown = styled(Button)`
  border: 0;
  background-color: transparent;
  min-width: 1px;
  padding: 0;
  align-self: flex-start;
  width: 20px;
  height: 24px;
  color: #575757;

  &:hover{
    color: #e9e9e9;
    background-color: transparent;
  }

  .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
  }

  + .MuiTypography-h2{
    padding-left: 0;
  }
`;

export const CustomDivider = styled(Divider)`
  border-color: #373737;
  margin-top: 39px;
  margin-bottom: 39px;
`;

export const WrapperBox = styled(Box)`
  display: flex;
  gap: 15px;
`;

export const SubmitWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  button{
    border: 1px solid #E3C050;
    font: 700 14px/18px Roboto, Arial, sans-serif;
    display: inline-block;
    vertical-align: top;
    padding: 17px 30px;
    min-width: 250px;
    background-color: #000;
    color: #fff;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    border-radius: 6px;
    text-transform: uppercase;

    &:hover{
      color: #fff;
      background-color: #454648;
    }
  }
`;

export const LesButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-color: #131313;
  margin: 0 -24px;
  padding: 48px 24px;

  &:empty{
    display: none;
  }

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 24px;
    z-index: -1;
  }

  &:before{
    border-radius: 0 0 11px 11px;
    background-color: #1f1f1f;
  }

  button{
    border: 1px solid #E3C050;
    font: 700 14px/18px Roboto, Arial, sans-serif;
    display: inline-block;
    vertical-align: top;
    padding: 17px 30px;
    max-width: 177px;
    background-color: #000;
    color: #fff;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    border-radius: 6px;
    text-transform: uppercase;
    width: 100%;

    &.btn-secondary{
      background-color: transparent;
      border-color: #373737;
      color: #c0c0c0;
    }

    &:hover{
      color: #fff;
      background-color: #454648;
    }
  }
`;

export const LesRowWrapper = styled(Box)`
  position: relative;

  + .SecButtonsWrapper{
    margin-top: 0;
    padding-top: 0;

    &:before{
      display: none;
    }
  }

  + .LesButtonWrapper{
    margin-bottom: -24px;
  }

  + .LesRowWrapper{
    background-color: #131313;
    margin: 0 -24px -14px;
    padding: 72px 24px 0;
    z-index: 1;

    &:before,
    &:after{
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
    }

    &:before{
      top: 0;
      height: 24px;
    }

    &:after{
      border-radius: 11px;
      background-color: #1f1f1f;
      top: 48px;
      bottom: 0;
    }
  
    &:before{
      border-radius: 0 0 11px 11px;
      background-color: #1f1f1f;
    }
  }

  .btn-remove{
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #373737;
    background-color: #2b2b2b;
    border-radius: 4px;
    color: #8C8E95;
    padding: 7px;
    font: 700 12px/14px Roboto, Arial, sans-serif;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

    &:hover{
      background-color: #000;
      color: #fff;
    }

    .MuiSvgIcon-root{
      width: 14px;
      height: 14px;
      margin-right: 6px;
      margin-left: -2px;
      vertical-align: -0.11em;
    }
  }
`;

export const SecButtonsWrapper = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  z-index: 1;
  background-color: #131313;
  margin: 0 -24px -24px;
  padding: 48px 24px 0;

  &:empty{
    display: none;
  }

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 24px;
    z-index: -1;
  }

  &:before{
    border-radius: 0 0 11px 11px;
    background-color: #1f1f1f;
  }

  button{
    border: 1px solid #E3C050;
    font: 700 14px/18px Roboto, Arial, sans-serif;
    display: inline-block;
    vertical-align: top;
    padding: 17px 30px;
    max-width: 177px;
    background-color: #000;
    color: #fff;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    border-radius: 6px;
    text-transform: uppercase;
    width: 100%;

    &.btn-secondary{
      background-color: transparent;
      border-color: #373737;
      color: #c0c0c0;
    }

    &:hover{
      color: #fff;
      background-color: #454648;
    }
  }
`;

export const MainHeading = styled(Typography)`
  color: #E9E9E9;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.1;
  margin: 0 0 36px;
`;

export const LesHeading = styled(Typography)`
  color: #E9E9E9;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.1;
  margin: 0 0 27px;
  padding-top: 7px;
`;

export const RowHeading = styled(Typography)`
  color: #E9E9E9;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.1;
  margin: 0 0 36px;
  flex-grow: 1;
  padding-left: 36px;
`;

export const WidgetBox = styled(Box)`
  background-color: #1f1f1f;
  border-radius: 11px;
  padding: 24px;
  flex-shrink: 0;
  width: 100%;
  max-width: 538px;
`;

export const FormRow = styled(Box)`
  margin-bottom: 16px;
`;

export const LabelHeading = styled(Typography)`
  color: #8C8E95;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.1;
  margin: 0 0 8px;
`;

export const FormField = styled(Field)`
  color: #e9e9e9;
  box-sizing: border-box;
  padding: 8px 12px;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 44px;
  font-size: 14px;
  line-height: 18px;

  &:focus{
    outline: none;
    border-color: #E3C050;
  }
`;

export const CustomFormControl = styled(Box)`
  + img{
    display: none;
  }

  label{
    color: #e9e9e9;
    box-sizing: border-box;
    padding: 8px 12px;
    background-color: #131313;
    border: 1px solid #373737;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 11px;
    width: 100%;
    height: 44px;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;

    &:focus{
      outline: none;
      border-color: #E3C050;
    }
  }

  input{
    clip: rect(0,0,0,0);
    border: 0;
    background-color: transparent;
    width: 1px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
  }
`;

export const TextAreaField = styled(TextField)`
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  width: 100%;
  
  .MuiInputBase-formControl{
    padding: 8px 12px;
    font-size: 14px;
    height: 116px;
    line-height: 18px;
    color: #e9e9e9;
    display: block;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &:focus{
    outline: none;
    border-color: #E3C050;
  }

  input{
    padding: 0;
  }

  fieldset{
    border: 0;
    box-shadow: none;
  }
`;

export const TagsFormControl = styled(Box)`
  box-sizing: border-box;
  padding: 8px 12px;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  min-height: 44px;

  .MuiInputBase-formControl{
    padding: 0 !important;
    border: 0;
    box-shadow: none;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  button{
    color: #e9e9e9;
  }

  fieldset{
    border: 0;
    box-shadow: none;
  }

  input{
    padding: 4px 0 0 !important;
  }
`;

export const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  &:empty{
    display: none;
  }

  .MuiButtonBase-root{
    border: 0;
    border-radius: 0;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 1.1;

    + .MuiButtonBase-root{
      
      &:before{
        content: ',';
        margin-right: 5px;
      }
    }

    .MuiChip-label{
      padding-left: 0;
    }

    .MuiSvgIcon-root{
      color: inherit;
      width: 12px;
      height: 12px;
      margin-right: 0;

      &:hover{
        color: #fff;
      }
    }
  }
`;

export const FormWrapper = styled('div')`
`;

export const CustomCheckbox = styled(FormControlLabel)`
  margin-left: 0;
  margin-right: 0;
  border: 1px solid #373737;
  border-radius: 6px;

  .MuiFormControlLabel-label,
  .MuiSvgIcon-root{
    display: none;
  }

  .MuiCheckbox-root,
  .MuiTouchRipple-root,
  .MuiTouchRipple-root:after,
  .MuiTouchRipple-root:before{
    display: flex;
    padding: 0;
  }

  .MuiCheckbox-root:hover{
    background-color: transparent;
  }

  .MuiCheckbox-root,
  .MuiTouchRipple-root{
    border-radius: 0;
    color: #c0c0c0;
  }

  .MuiTouchRipple-root{
    position: static;
    padding: 0;
    width: auto;
    height: auto;
  }

  .MuiTouchRipple-root:before{
    content: 'Paid';
    border-radius: 6px 0 0 6px;
  }

  .MuiTouchRipple-root:before,
  .MuiTouchRipple-root:after{
    height: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-width: 68px;
    border: 1px solid transparent;
    padding: 7px 20px 10px;
  }

  .MuiTouchRipple-root:after{
    content: 'Free';
    border-radius: 0 6px 6px 0;
    border-color: #E3C050;
    color: #fff;
  }

  .Mui-checked{

    .MuiTouchRipple-root:before{
      color: #fff;
      border-color: #E3C050;
    }

    .MuiTouchRipple-root:after{
      color: inherit;
      border-color: transparent;
    }
  }
`;

export const CustomCheckboxLocked = styled(FormControlLabel)`
  margin-left: 0;
  margin-right: 0;
  border: 1px solid #373737;
  border-radius: 6px;

  .MuiFormControlLabel-label,
  .MuiSvgIcon-root{
    display: none;
  }

  .MuiCheckbox-root,
  .MuiTouchRipple-root,
  .MuiTouchRipple-root:after,
  .MuiTouchRipple-root:before{
    display: flex;
    padding: 0;
  }

  .MuiCheckbox-root:hover{
    background-color: transparent;
  }

  .MuiCheckbox-root,
  .MuiTouchRipple-root{
    border-radius: 0;
    color: #c0c0c0;
  }

  .MuiTouchRipple-root{
    position: static;
    padding: 0;
    width: auto;
    height: auto;
  }

  .MuiTouchRipple-root:before{
    content: 'Locked';
    border-radius: 6px 0 0 6px;
  }

  .MuiTouchRipple-root:before,
  .MuiTouchRipple-root:after{
    height: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-width: 68px;
    border: 1px solid transparent;
    padding: 7px 20px 10px;
  }

  .MuiTouchRipple-root:after{
    content: 'Available';
    border-radius: 0 6px 6px 0;
    border-color: #E3C050;
    color: #fff;
  }

  .Mui-checked{

    .MuiTouchRipple-root:before{
      color: #fff;
      border-color: #E3C050;
    }

    .MuiTouchRipple-root:after{
      color: inherit;
      border-color: transparent;
    }
  }
`;