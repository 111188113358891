import FeatureTableColumn from "components/FeatureTableColumn";
import { Wrapper, Section } from "./style";

const CapabilitiesSection = ({pTop, pBottom}: any) => (
  <Section
    className="CapabilitiesSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <FeatureTableColumn />
    </Wrapper>
  </Section>
);

export default CapabilitiesSection;