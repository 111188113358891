import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SparkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M9.47917 2.20831L2.1875 10.9583H8.75L8.02083 16.7916L15.3125 8.04165H8.75L9.47917 2.20831Z" stroke="currentColor" strokeWidth="1.46125" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};