import { Box, styled, Typography, Link } from '@mui/material';

export const CanvasSidebarWrapper = styled('div')`
  position: fixed;
  left: 0;
  top: 56px;
  bottom: 0;
  z-index: 9;
  background-color: #1f1f1f;
  transform: translateX(-300px);
  transition: transform 0.3s ease;
  padding: 20px;
  width: 280px;
  min-width: 280px;

  @media (min-width: 1200px){
    padding: 0;
    position: static;
    background-color: transparent;
    transform: none;
    min-width: 316px;
    width: 316px;
  }
  @media (max-width: 1199px){
    overflow-x: hidden;
    overflow-y: auto;
  }

  .canvasSidebarActive &{
    transform: translateX(0px);

    @media (min-width: 1200px){
      transform: none;
    }
  }

  .csw-btn-close{
    position: absolute;
    top: 16px;
    right: 5px;
    color: #fff;
    min-width: 1px;
    width: 30px;
    height: 30px;

    @media (min-width: 1200px){
      display: none !important;
    }
  }
  
  .can-tag{
    display: inline-block;
    vertical-align: top;
    padding: 4px 8px;
    color: #8C8E95;
    font-size: 10px;
    line-height: 1;
    background-color: rgba(0,0,0,0.1);
    position: relative;
    top: 2px;
  }

  .cswBoxWrapper{
    margin-bottom: 36px;
  }

  .cswListLink{
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
    color: #8C8E95;
    border: 1px solid transparent;
    width: 100%;
    padding: 6px 9px 10px;
    border-radius: 6px;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    font-size: 16px;
    line-height: 20px;

    &.locked {
      pointer-events: none;
      cursor: default;
    }

    &:hover, &.active{
      color: #fff;
      background-color: #000;
      border-color: #E3C050;
    }

    &.active{
      font-weight: 700;
    }

    svg{
      width: 18px;
      height: 18px;
    }

    .can-text{
      flexGrow: 1;
    }
  }

  .cswcswLink{
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #8C8E95;
    border: 1px solid transparent;
    width: 100%;
    padding: 6px 9px 10px;
    border-radius: 6px;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;

    &:hover, &.active{
      color: #fff;
      background-color: #000;
      border-color: #E3C050;
    }

    &.active{
      font-weight: 700;
    }
  }
`;

export const BoxWrapper = styled(Box)`
  margin-bottom: 36px;
`;

export const BoxWrap = styled(Box)`
  margin-bottom: 21px;

  @media (min-width: 768px){
    margin-bottom: 41px;
  }
`;

export const BoxTopicWrapper = styled(Box)`
  border-bottom: 1px solid #373737;
  margin-bottom: 4px;
`;

export const TopHeading = styled(Typography)`
  color: #C0C0C0;
  font-size: 16px;
  line-height: 20px;
  display: block;
  width: 100%;
  padding: 2px 10px 6px;

  svg{
    width: 18px;
    height: 18px;
    vertical-align: -0.14em;
    margin-right: 8px;
  }
`;

export const BackLink = styled(Link)`
  margin-bottom: 36px;color: #C0C0C0;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #575757;
  border-radius: 6px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 10px 4px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

  &:hover{
    background-color: #000;
    color: #fff;
    border-color: #E3C050;
  }

  svg{
    width: 14px;
    height: 14px;
    vertical-align: -0.14em;
    margin-right: 4px;
  }
`;

export const SidebarSectionWrapper = styled('div')`
  padding: 0 0 25px;
`;