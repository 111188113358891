import { Box, styled, Typography } from '@mui/material';

export const CanvasChatWrapper = styled('div')`
  text-align: center;
`;

export const BoxHeading = styled(Typography)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 0 0 18px;

  @media (min-width: 768px){
    font-size: 20px;
    line-height: 28px;
  }
  @media (min-width: 992px){
    font-size: 24px;
    line-height: 32px;
  }
`;

export const BoxOpenChat = styled(Box)`
  margin-bottom: 18px;

  button{
    background-color: #000;
    border: 1px solid #E3C050;
    padding: 13px 20px;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;

    &:hover{
      background-color: #3f3f3f;
      color: #fff;
    }

    svg{
      width: 18px;
      height: 18px;
      marginLeft: 8px;
    }

    img{
      margin-right: 8px;
      max-width: 100%;
      height: auto;
    }
  }
`;

export const BoxSubheading = styled(Typography)`
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
  color: #22C55E;
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;

  .can-status-bubble{
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: currentColor;
    box-shadow: 0 0 0 4px rgba(74, 222, 128, 0.0926);
  }
`;