import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M13.8542 8.77081H3.64583C2.84042 8.77081 2.1875 9.42373 2.1875 10.2291V15.3333C2.1875 16.1387 2.84042 16.7916 3.64583 16.7916H13.8542C14.6596 16.7916 15.3125 16.1387 15.3125 15.3333V10.2291C15.3125 9.42373 14.6596 8.77081 13.8542 8.77081Z" stroke="currentColor" strokeWidth="1.46125" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M5.10413 8.77081V5.85415C5.10413 4.88721 5.48824 3.95988 6.17197 3.27615C6.85569 2.59243 7.78302 2.20831 8.74996 2.20831C9.71689 2.20831 10.6442 2.59243 11.328 3.27615C12.0117 3.95988 12.3958 4.88721 12.3958 5.85415V8.77081" stroke="currentColor" strokeWidth="1.46125" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};