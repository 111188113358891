import { useGetCourseQuery, useUpdateCourseMutation } from "api/courses";
import { useNavigate, useParams } from "react-router-dom";
import { CourseBuilder } from "components/CourseBuilder";
import { imageUpload } from "utils/aws";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { useEffect } from "react";
import { superAdmin } from "common/constants";

export const EditCourse = () => {
  const { courseId } = useParams();
  const { data } = useGetCourseQuery(courseId);
  const [user, isLoading] = useAuthState(auth);

  const [updateCourse] = useUpdateCourseMutation();
  const navigate = useNavigate();

  const submitHandler = async (values: any) => {
    var img = values.img;

    const data = {
      ...values,
      img,
    };

    updateCourse(data).then((res: any) => {
      if (res.data) navigate(`/course/${values.slug}`);
    });
  };

  useEffect(() => {
    if (data?.uid !== user?.uid && !superAdmin.includes(user?.uid || "")) {
      navigate(-1);
    }
  }, [user]);

  return (
    <>
      {data && (
        <CourseBuilder submitHandler={submitHandler} initialData={data} />
      )}
    </>
  );
};
