import { Box, Button, Typography } from "@mui/material";
import { Field, FieldArray, useFormikContext } from "formik";
import { Lesson } from "./Lesson";
import { ButtonMoveDown, ButtonMoveUp, CustomDivider, FormField, FormRow, LabelHeading, RowHeading, SecButtonsWrapper, SubmitWrapper, WidgetBox, WrapperBox } from "./style";
import { ArrowMoveDown } from "components/Icons/ArrowMoveDown";
import { ArrowMoveUp } from "components/Icons/ArrowMoveUp";

export const Section = ({ purchase }: any) => {
  const { values, errors, touched, setFieldValue }: any = useFormikContext();

  const sortedSection = values.tasks[0].pos ? values.tasks.sort((a: any, b: any) => a.pos - b.pos) : values.tasks;

  const sectionUp = (x: number) => {
    if (sortedSection.length > x && x !== 0) {
      setFieldValue(`tasks.${x - 1}.pos`, x);
      setFieldValue(`tasks.${x}.pos`, x - 1);
    }
  };

  const sectionDown = (x: number) => {
    if (sortedSection.length-1 > x) {
      setFieldValue(`tasks.${x + 1}.pos`, x);
      setFieldValue(`tasks.${x}.pos`, x + 1);
    }
  };

  return (
    <>
      <FieldArray
        name="tasks"
        render={(arrayHelpers) => (
          <div className="field-area-wrap">
            {sortedSection && sortedSection.length > 0
              ? sortedSection.map((task: any, index: number) => (
                  <div className="field-arrays" key={index}>
                    {index > 0 && (
                      <CustomDivider />
                    )}
                    <WrapperBox>
                      <ButtonMoveUp onClick={() => sectionUp(index)}>
                        <ArrowMoveUp />
                      </ButtonMoveUp>
                      <ButtonMoveDown onClick={() => sectionDown(index)}>
                        <ArrowMoveDown />
                      </ButtonMoveDown>
                      <RowHeading
                        variant="h2"
                      >
                        Section {index + 1}:
                      </RowHeading>
                      <WidgetBox>
                        <FormRow>
                          <LabelHeading
                            variant="h3"
                          >
                            Section Name
                          </LabelHeading>
                          <FormField
                            name={`tasks.${index}.name`}
                          />
                          {errors.tasks &&
                          touched.tasks &&
                          (touched as any).tasks[index]?.name ? (
                            <div>{(errors as any).tasks[index].name}</div>
                          ) : null}
                        </FormRow>
                        <Lesson index={index} purchase={purchase} />
                        <SecButtonsWrapper className="SecButtonsWrapper">
                          {index > 0 && (
                            <button
                              className="btn-secondary"
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              DELETE SECTION
                            </button>
                          )}
                          {sortedSection?.length - 1 === index && (
                            <button
                              type="button"
                              onClick={() =>
                                arrayHelpers.insert(index + 1, {
                                  name: "",
                                  pos: index + 1,
                                  topics: [
                                    {
                                      pos: 0,
                                      slug: "",
                                      title: "",
                                      video: "",
                                    },
                                  ],
                                })
                              } // insert an empty string at a position
                            >
                              ADD SECTION
                            </button>
                          )}
                        </SecButtonsWrapper>
                      </WidgetBox>
                    </WrapperBox>
                  </div>
                ))
              : null}
          </div>
        )}
      />

      <CustomDivider />
      <SubmitWrapper>
        <button type="submit">Submit</button>
      </SubmitWrapper>
    </>
  );
};
