import { styled } from "@mui/material";
import { Box, Button, Divider } from "@mui/material";
import { CanvasSidebar } from "components/CanvasSidebar";
import { CanvasVideo } from "components/CanvasVideo";
import { CanvasChat } from "components/CanvasChat";
import { useEffect } from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCourseQuery } from "api/courses";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { superAdmin } from "common/constants";
import { CanvasSideContentWrapper, CanvasSidebarOpener } from "./style";

export const Course = () => {
  const { courseId, videoId } = useParams();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const { data } = useGetCourseQuery(courseId, {
    refetchOnMountOrArgChange: true,
  });

  let selectedTopic = false;
  data?.tasks.forEach((task: any) => {
    if (!selectedTopic) {
      selectedTopic = task.topics.find((topic: any) => topic.slug === videoId);
    }
  });

  const handleSidebarCollapse = function () {
    document.body.classList.toggle("canvasSidebarActive");
  };

  useEffect(() => {
    if (!videoId && data) {
      navigate(`${data?.tasks[0].topics[0]?.slug}`);
    }
  }, [data]);

  if (!selectedTopic) return null;

  return (
    <>
      <CanvasSidebarOpener className="CanvasSidebarOpener">
        <Button
          className="csw-btn-open"
          onClick={handleSidebarCollapse}
        >
          Open Sidebar
          <MenuOpenIcon />
        </Button>
      </CanvasSidebarOpener>
      {user && (user?.uid === data?.uid || superAdmin.includes(user?.uid)) && (
        <Box
          sx={{
            marginBottom: "24px",
          }}
        >
          <Button
            onClick={() => navigate(`/course/${courseId}/edit`)}
            sx={{
              display: "block",
              borderRadius: "6px",
              backgroundColor: "#000",
              border: "1px solid #E3C050",
              color: "#fff",
              padding: "16px 18px",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "1",
              textDecoration: "none",
              textAlign: "center",
              textTransform: "uppercase",
              cursor: "pointer",

              "&:hover": {
                color: "#fff",
                textDecoration: "none",
                backgroundColor: "#3f3f3f",
              },

              svg: {
                width: "18px",
                height: "18px",
                verticalAlign: "-0.15em",
              },
            }}
          >
            <EditIcon /> Edit Course
          </Button>
        </Box>
      )}
      <CanvasSideContentWrapper>
        <Box
          className="cscwBoxWrapper"
        >
          <CanvasSidebar course={data?.tasks} />
          <Box
            sx={{
              flexGrow: "1",
            }}
          >
            <CanvasVideo topic={selectedTopic} />
            <Divider
              className="cscwDivider"
            />
            <CanvasChat />
          </Box>
          {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>Create Your Lean Canvas</Typography>
            <Link>Introduction</Link>
            <Link>Customer Segments</Link>
            <Link>Problems</Link>
            <Link>Revenue</Link>
            <Link>Solution</Link>
            <Link>Unique Value Proposition</Link>
            <Link>Channel</Link>
            <Link>Cost Structures</Link>
            <Link>Unfair Advantage</Link>

            <Typography>Build Your Financial Model</Typography>
            <Link>Introduction</Link>
            <Link>Revenue</Link>
            <Link>COGS</Link>
            <Link>COGS</Link>
            <Link>Expenses</Link>
          </Box>

          <Box pl={"140px"}>
            <Box textAlign={"center"} sx={{ height: "300px" }}>
              Video Player Here
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="autoplay"
              />
              <Box>
                <Button>Home</Button>
                <Button>Click</Button>
                <Button>Share this course</Button>
              </Box>

              <Box>
                Circle
              </Box>
            </Box>

            <Box>
              <Typography variant="h3">Create Your Lean Canvas</Typography>
              <Typography>
                Learn how to create a lean canvas with step-by-step video
                instructions
              </Typography>
            </Box>

            <Box mt={4} textAlign={"center"}>
              <Typography>Questions? Let's chat</Typography>
              <Button>Open Discord</Button>
              <p>4967</p>
            </Box>
          </Box> */}
        </Box>
      </CanvasSideContentWrapper>
    </>
  );
};
