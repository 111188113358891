import { styled, Box, Typography, Divider } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 24px;
  width: 100%;

  &.post-column--list-view{

    @media (min-width: 768px){
      display: flex;
      flex-direction: row-reverse;
      gap: 40px;
    }
  }
`;

export const ImageHolder = styled(Box)`
  margin: 0 0 24px;
  overflow: hidden;
  border-radius: 9px;

  .post-column--list-view &{

    @media (min-width: 768px){
      width: 46.5%;
      flex-shrink: 0;
      margin: 0;
    }
  }
  
  img{
    width: 100%;
    border-radius: inherit;
    height: auto;
  }
`;

export const DescriptionWrapper = styled(Box)`
  

  .post-column--list-view &{

    @media (min-width: 768px){
      align-self: center;
    }
  }
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: #E9E9E9;
  margin-bottom: 8px;

  @media (min-width: 992px){
    font-size: 22px;
  }
`;

export const Description = styled(Typography)`
  color: #C0C0C0;
  font-size: 18px;
  line-height: 1.5;
`;

export const TaglineHeading = styled(Typography)`
  font-size: 14px;
  line-height: 1.35;
  font-weight: 700;
  letter-spacing: 2px;
  color: #E3C050;
  display: block;
  margin-bottom: 8px;
  text-transform: uppercase;
`;