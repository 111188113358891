export const SET_USER_SESSION = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};
export const GET_USER_SESSION = (user) => {
  return localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
};
export const LOGOUT_USER_SESSION = (user) => {
  localStorage.removeItem("user", JSON.stringify(user));
};

export const superAdmin = ["e3aMH54s21MfcTwwzRrEaFvufIi1"];
