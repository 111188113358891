import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArrowLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M11.0833 7H2.91663" stroke="currentColor" strokeWidth="1.169" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M6.99996 11.0833L2.91663 7.00001L6.99996 2.91667" stroke="currentColor" strokeWidth="1.169" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};