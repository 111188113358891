import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;

  .CardBox{

    @media (max-width: 575px){
      max-width: 316px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
`;

export const PostsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 24px;
  margin-left: -12px;
  margin-right: -12px;
`;

export const Col = styled(Box)`
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  width: 100%;

  @media (min-width: 768px){
    width: 50%;
  }

  &.full{
    width: 100%;

    @media (min-width: 768px){
      width: 100%;
    }
  }
`;