import { styled, Typography, Divider } from '@mui/material';

export const CanvasVideoWrapper = styled('div')`
  
  .video-responsive{
    width: 100%;
    max-width: 640px;
    margin: 48px auto 24px;
    overflow: hidden;
    border-radius: 16px;
    position: relative;
    padding-top: 34.65%;
    border: 1px solid #2b2b2b;
    background-color: #1f1f1f;

    > div{
      position: absolute;
      left: 0;
      top: 0;
      width: 100% !important;
      height: 100% !important;
    }

    iframe {
      width: 100% !important;
      height: 100% !important;
    }

    &.alt {
      iframe{
        position: absolute;
        left: 0;
        top: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .cvwBoxWrapper{
    text-align: center;
  }
`;

export const TopicMainDescription = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: #8C8E95;

  @media (min-width: 992px){
    font-size: 20px;
    line-height: 30px;
  }
`;

export const TopicMainHeading = styled(Typography)`
  color: #C0C0C0;
  font-size: 22px;
  line-height: 1.1;
  font-weight: 700;
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  margin-bottom: 16px;

  .MuiSvgIcon-root{
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
`;