import { useAddCourseMutation } from "api/courses";
import { useNavigate } from "react-router-dom";
import { CourseBuilder } from "components/CourseBuilder";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { toast } from "react-toastify";

export const AddCourse = () => {
  const [user] = useAuthState(auth);
  const [addCourse] = useAddCourseMutation();
  const navigate = useNavigate();

  const submitHandler = (data: any) => {
    const courseData = {
      ...data,
      creator: {
        displayName: user?.displayName,
        email: user?.email,
        photoURL: user?.photoURL,
      },
    };

    addCourse(courseData)
      .then((res: any) => {
        if (res.data) navigate(`/course/${data.slug}`);
      })
      .catch((error) => toast.error(error.message));
  };

  return <CourseBuilder submitHandler={submitHandler} />;
};
