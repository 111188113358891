import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const VidRecorderIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M17.2488 5.24884L11.9988 8.99884L17.2488 12.7488V5.24884Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M10.4988 3.75116H2.24878C1.42035 3.75116 0.748779 4.42273 0.748779 5.25116V12.7512C0.748779 13.5796 1.42035 14.2512 2.24878 14.2512H10.4988C11.3272 14.2512 11.9988 13.5796 11.9988 12.7512V5.25116C11.9988 4.42273 11.3272 3.75116 10.4988 3.75116Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};